import 'core-js/stable'
import 'regenerator-runtime/runtime'
import Vue from 'vue'
import App from './App.vue'
import vuetify from '@/plugins/vuetify'
import router from './router'
import axios from 'axios'
import API from './API'
import Store from './store'
import './registerServiceWorker'
import moment from 'moment'
import 'moment/locale/he'
import TinyMCE from '@tinymce/tinymce-vue'
import pusher from 'pusher-js'

axios.defaults.baseURL = 'https://givolpower.givol-pharmacy.co.il/'
axios.defaults.withCredentials = true
let AccessToken = Store.getters.getAccessToken

if (AccessToken) { axios.defaults.headers.common['loginToken'] = `${AccessToken}` }

// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN
window.axios = axios
window.API = API
window.Store = Store
window.Pusher = pusher
window.moment = moment
// window.M = M
Vue.component('Editor', TinyMCE)

Vue.config.productionTip = false
Vue.filter('formatDate', function (value) {
  if (value) {
    return moment(String(value)).format('H:mm:ss DD/MM/YYYY')
  }
})
Vue.filter('formatDates', function (value) {
  if (value) {
    return moment(String(value)).format('DD/MM/YYYY')
  }
})
Vue.filter('formathortDates', function (value) {
  if (value) {
    return moment(String(value)).format('DD/MM/YY')
  }
})
Vue.filter('formatBDay', function (value) {
  if (value) {
    return moment().diff(String(value), 'years')
  }
})
Vue.filter('formatDateDay', function (value) {
  if (value) {
    return moment(String(value)).format('DD')
  }
})
Vue.filter('formatDateDayName', function (value) {
  if (value) {
    return moment(String(value)).format('dddd')
  }
})
Vue.filter('formatDateMonth', function (value) {
  if (value) {
    return moment(String(value)).format('MM')
  }
})
Vue.filter('formatFromNow', function (value) {
  if (value) {
    return moment(String(value)).fromNow()
  }
})
Vue.filter('formatDateMonthName', function (value) {
  if (value) {
    return moment(String(value)).format('MMMM')
  }
})
Vue.filter('formatDateYear', function (value) {
  if (value) {
    return moment(String(value)).format('YYYY')
  }
})
Vue.filter('formatTimeOnly', function (value) {
  if (value) {
    return moment(value, 'HH:mm:ss').format('H:mm')
  }
})
Vue.filter('formatTime', function (value) {
  if (value) {
    return moment(String(value)).format('H:mm')
  }
})

export const bus = new Vue()

new Vue({
  data: () => ({
    menuShow: true
  }),
  vuetify,
  router,
  Store,
  render: h => h(App)
}).$mount('#app')
